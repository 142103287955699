html,
body,
#root {
  min-height: 0;
  min-width: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-basis: 100%;
  height: 100%;
  background-color: #f9f3eb !important;
  z-index: -2;
}

.ant-table-filter-dropdown-link {
  color: #a1c6da !important;
}
.ant-table-cell {
  cursor: pointer;
  padding: 0 16px;
  background-color: white;
}

table {
  /* box-shadow: 0px 2px 20px 0px #deaf8d33; */
  border-radius: 5px !important;
  font-weight: bold;
}

.ant-table {
  border-radius: 5px !important;
  background: transparent !important;
}
.ant-table-container {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.ant-table-placeholder {
  display: none;
}
.ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}
.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

table > tbody > tr > td:first-child {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}
table > tbody > tr > td:last-child {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

table > tbody > tr:last-child td:first-child {
  border-bottom-left-radius: 5px;
}
table > tbody > tr:last-child td:last-child {
  border-bottom-right-radius: 5px;
}

.ant-breadcrumb-link a {
  color: #282F33;
  font-weight: bold;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-breadcrumb-link a:hover {
  color: #282F33CC;
}

table > tbody > tr {
  box-shadow: 0px 2px 20px 0px #deaf8d33;
  border-radius: 5px;
}

.ant-table-tbody > tr > td {
  border-top: 1px solid #deaf8d33 !important;
  border-bottom: none;
}


.ant-table-tbody > tr.ant-table-row:hover > td{
  background: #eeeeee !important;
}
/* .ant-table-tbody > tr {
  margin-top: 1px !important;
} */
.ant-table-thead > tr > th {
  text-transform: uppercase;
  font-weight: 600 !important;
  font-size: 0.8rem;
  padding: 16px;
  background-color: white !important;
  border-bottom: 1px solid #deaf8d33 !important;
}


.ant-notification {
  margin-right: 10px;
}
.ant-notification-notice {
  max-width: calc(100vw - 10px * 2);
}

.ant-select-dropdown {
  z-index: 9000 !important;
}

.ant-dropdown {
  z-index: 9000 !important;
}
.ant-dropdown-menu {
  border-radius: 5px !important;
}

.ant-dropdown::before {
  bottom: 0px !important;
}

.ant-dropdown.ant-dropdown-placement-bottomLeft {
  max-width: 100%;
}

.ant-dropdown.ant-dropdown-placement-bottomLeft li {
  max-width: 100%;
  display: list-item;
}

.anticon.ant-notification-notice-icon-success {
  color: #139982;
}

.ant-tooltip-inner {
  border-radius: 10px !important;
}

.ant-tooltip {
  z-index: 9000 !important;
}

.ant-drawer {
  z-index: 3000 !important;
}
.ant-modal-wrap,
.ant-modal-mask {
  z-index: 3000 !important;
}

.ant-pagination-item,
.ant-pagination-item-link {
  border-radius: 5px !important;
}

.ant-popover-inner {
  border-radius: 10px !important;
}

.ant-popover-message-title {
  font-weight: bold;
}

.ant-popover-buttons button {
  border-radius: 5px;
  font-weight: bold;
}


html {
  font-family: 'Nunito';
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Nunito';
}
#root {
  font-family: 'Nunito';
}

::selection {
  background: #f99746;
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 200;
  src: url('./fonts/nunito-v13-latin-200.woff') format('woff');
}

@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 200;
  src: url('./fonts/nunito-v13-latin-200italic.woff') format('woff');
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/nunito-v13-latin-300.woff') format('woff');
}

@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 300;
  src: url('./fonts/nunito-v13-latin-300italic.woff') format('woff');
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/nunito-v13-latin-regular.woff') format('woff');
}

@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 400;
  src: url('./fonts/nunito-v13-latin-italic.woff') format('woff');
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/nunito-v13-latin-700.woff') format('woff');
}

@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 700;
  src: url('./fonts/nunito-v13-latin-700italic.woff') format('woff');
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 900;
  src: url('./fonts/nunito-v13-latin-900.woff') format('woff');
}

@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 900;
  src: url('./fonts/nunito-v13-latin-900italic.woff') format('woff');
}

/* Excalidraw custom styles */
.excalidraw .excalidraw-tooltip-wrapper {
  display: none !important;
}
.excalidraw .App-bottom-bar .App-toolbar-content > .ToolIcon_type_button:first-child {
  display: none !important;
}
.excalidraw .help-icon {
  display: none !important;
}
.excalidraw .ToolIcon__library {
  display: none !important;
}
.excalidraw .ToolIcon__lock {
  display: none !important;
}
.App-menu_top > .Stack_vertical > .zen-mode-transition:first-child {
  display: none !important;
}
.excalidraw .App-bottom-bar {
  bottom: 40px !important;
}
.excalidraw .Island .panelColumn fieldset:last-child,
.excalidraw .Island .panelColumn fieldset:nth-last-child(2) {
  visibility: hidden;
}
.excalidraw .Island.App-menu__left {
  min-height: 200px;
  width: 202px;
  display: flex;
}
.excalidraw .Island.App-menu__left button[title='Send to back — Cmd+Option+['],
.excalidraw .Island.App-menu__left button[title='Send backward — Cmd+['],
.excalidraw .Island.App-menu__left button[title='Bring to front — Cmd+Option+]'],
.excalidraw .Island.App-menu__left button[title='Bring forward — Cmd+]'] {
  display: none;
  margin-bottom: -100px;
}
.excalidraw .Island.App-menu__left .buttonList button[title='Duplicate — Cmd+D'],
.excalidraw .Island.App-menu__left .buttonList button[title='Delete'] {
  margin-bottom: 20px;
}
.excalidraw .panelColumn fieldset:nth-last-child(2) {
  margin-top: -40px;
}
